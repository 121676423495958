import React from "react"

import "./EventPoster.css"

const EventPoster = (props) => {

  return(
    <div className="EventPosterContainer">
      <div className="EventPosterWrapper">
        <div className={"EventPoster " + props.pic} />
      </div>
    </div>
  )
}

export default EventPoster
