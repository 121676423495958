import React, { useState, useEffect } from "react"

import NavBar from "../component/NavBar"
import EventPoster from "./events/EventPoster"

import "./Events.css"

class Events extends React.Component {

  render() {
    return(
      <div className="Page">
        <NavBar showLogo={true} />
        <div id="Events" className="Content Veiling">
          <EventPoster pic="hamvas125" />
          <EventPoster pic="Sesshin20220220" />
          <EventPoster pic="Sesshin20211107" />
        </div>
      </div>
    )
  }
}

export default Events
